import * as Sentry from '@sentry/nextjs';

import { events as eventService } from '@boss/services/client';

const participateEvent = async ({
  eventId,
  data,
  isLoggedIn,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
}) => {
  try {
    return await eventService.participate({ eventId, data, isLoggedIn });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Participate event',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

export { participateEvent };
