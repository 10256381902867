import * as bossClient from '@boss/boss-client';

/**
 * Participate event by event id
 * @param {string} eventId - event ID
 * @param data
 */
export const participate = async ({
  eventId,
  data,
  isLoggedIn,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
}) => {
  await bossClient.userEventClient.participateEvent({ eventId, data, isLoggedIn });
};
