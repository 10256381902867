import { ColorGroup, SimpleColor } from '@boss/types/b2b-b2c';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import DesktopColorPickerModal from './Desktop';
import MobileColorPickerModal from './Mobile';

type Translations = {
  title: string;
  searchLabel: string;
  subtitle: string;
  showMore: string;
  trending: string;
  showLabel: string;
  hideLabel: string;
  results: string;
  showLess: string;
  noResults: string;
  recentColors: string;
  premixedColors: string;
  byColorGroup: string;
};

type CallToAction = {
  label: string;
  onClick: () => void;
  highlight?: boolean;
};

type Props = {
  activeColorGroup?: ColorGroup;
  activeColors?: SimpleColor[];
  premixedColors?: SimpleColor[];
  recentViewedColors?: SimpleColor[];
  callToActions?: CallToAction[];
  className?: string;
  colorGroups?: ColorGroup[];
  colorsLoading?: boolean;
  hasSearched: boolean;
  onChangeColorClick: (color: SimpleColor, colorBase?: string) => void;
  onClose: () => void;
  onColorGroupClick: (colorGroup: ColorGroup) => void;
  onFavorite?: (color: SimpleColor) => void;
  onSearch: () => void;
  searchLoading?: boolean;
  searchResults?: SimpleColor[];
  searchValue?: string;
  selectedColor?: SimpleColor;
  setSearchValue: (val: string) => void;
  showMoreAmount?: number;
  translations: Translations;
  favoriteColorIds?: string[];
  colorGroupsLoading?: boolean;
  disclaimer?: string;
  colorType?: 'default' | 'transparant';
  defaultColorBase?: string;
  premixedColorBase?: string;
  hideRecentColors?: boolean;
  hideColorGroups?: boolean;
};

export type ColorPickerModalProps = Omit<Props, 'showMoreAmount'> & {
  handleShowMore: () => void;
  handleShowMoreSearch: () => void;
  hasShowMore: boolean;
  hasShowMoreSearch: boolean;
  showMore: boolean;
  showMoreSearch: boolean;
  favoriteColorIds?: string[];
};

const ColorPickerModal = ({
  showMoreAmount = 16,
  activeColors: initialColors,
  searchResults: initialSearchResults,
  ...props
}: Props) => {
  const [showMoreColors, setShowMoreColors] = useState(false);
  const [showMoreColorsSearch, setShowMoreColorsSearch] = useState(false);

  const hasShowMore = (initialColors?.length ?? 0) > showMoreAmount;
  const hasShowMoreSearch = (initialSearchResults?.length ?? 0) > showMoreAmount;

  const activeColors = showMoreColors ? initialColors : [...(initialColors ?? [])]?.splice(0, showMoreAmount);
  const searchResults = showMoreColorsSearch
    ? initialSearchResults
    : [...(initialSearchResults ?? [])]?.splice(0, showMoreAmount);

  const handleShowMore = () => setShowMoreColors(val => !val);
  const handleShowMoreSearch = () => setShowMoreColorsSearch(val => !val);

  const baseProps = {
    activeColors,
    handleShowMore,
    handleShowMoreSearch,
    hasShowMore,
    hasShowMoreSearch,
    searchResults,
    showMore: showMoreColors,
    showMoreSearch: showMoreColorsSearch,
  };

  return (
    <>
      <MobileColorPickerModal {...props} {...baseProps} className={twMerge('md:hidden', props.className)} />
      <DesktopColorPickerModal {...props} {...baseProps} className={twMerge('hidden md:flex', props.className)} />
    </>
  );
};

export default ColorPickerModal;
