import { getCollapsePropsWithOffset } from '@boss/constants/b2b-b2c';
import { SimpleColor } from '@boss/types/b2b-b2c';
import { twMerge } from 'tailwind-merge';

import { ColorPickerModalProps } from '..';
import { Alert, Button, ColorGroupButton, ColorsGrid, ColorsGridSkeleton, Modal, Presence, SearchBar } from '../../';

const MobileColorPickerModal = ({
  activeColorGroup,
  activeColors,
  callToActions,
  className,
  colorGroups,
  handleShowMore,
  hasShowMore,
  onChangeColorClick,
  onClose,
  onColorGroupClick,
  onFavorite,
  onSearch,
  showMore,
  translations,
  searchValue,
  setSearchValue,
  colorsLoading,
  selectedColor,
  hasSearched,
  searchLoading,
  searchResults,
  showMoreSearch,
  hasShowMoreSearch,
  handleShowMoreSearch,
  favoriteColorIds,
  disclaimer,
  colorType,
  defaultColorBase,
  premixedColorBase,
  premixedColors,
  recentViewedColors,
  hideRecentColors,
  hideColorGroups,
}: ColorPickerModalProps) => {
  const commonColorGridProps = {
    colorType,
    favoriteColorIds,
    onChangeColorClick: (color: SimpleColor) => onChangeColorClick(color, defaultColorBase),
    onFavorite,
    selectedColor,
    translations,
  };

  return (
    <Modal
      className={twMerge('rounded-b-modal rounded-l-modal flex flex-col gap-6 overflow-y-auto', className)}
      onClose={onClose}
    >
      <h2 className="mr-5">{translations.title}</h2>
      {!!premixedColors?.length && (
        <Presence
          {...getCollapsePropsWithOffset('-1.5rem')}
          id="mobile-search-color-picker-presence"
          loader={<ColorsGridSkeleton />}
          visible={!!premixedColors?.length}
        >
          <ColorsGrid
            {...commonColorGridProps}
            colors={premixedColors}
            onChangeColorClick={(color: SimpleColor) => onChangeColorClick(color, premixedColorBase)}
            title={translations.premixedColors}
          />
        </Presence>
      )}
      {!!recentViewedColors?.length && !hideRecentColors && (
        <Presence
          {...getCollapsePropsWithOffset('-1.5rem')}
          id="mobile-search-color-picker-presence"
          loader={<ColorsGridSkeleton />}
          visible={!!recentViewedColors?.length}
        >
          <ColorsGrid {...commonColorGridProps} colors={recentViewedColors} title={translations.recentColors} />
        </Presence>
      )}
      <div className="flex flex-col gap-3">
        {!hideColorGroups && <span className="font-bold">{translations.byColorGroup}</span>}
        {disclaimer && <Alert type="info">{disclaimer}</Alert>}
      </div>

      {!hideColorGroups && (
        <SearchBar
          onSearch={onSearch}
          searchLabel={translations.searchLabel}
          setSearchValue={setSearchValue}
          type="primary"
          value={searchValue}
        />
      )}
      {!hasSearched && colorGroups && !hideColorGroups && (
        <div className="grid grid-cols-2 gap-2 gap-y-4 sm:grid-cols-3">
          {colorGroups.map(colorGroup => (
            <ColorGroupButton
              active={colorGroup.code === activeColorGroup?.code}
              backgroundColor={colorGroup.rgb}
              key={colorGroup.code}
              name={colorGroup.name ?? colorGroup.code}
              onClick={() => onColorGroupClick(colorGroup)}
            />
          ))}
        </div>
      )}

      {!hideColorGroups && (
        <>
          <Presence
            {...getCollapsePropsWithOffset('-1.5rem')}
            id="mobile-search-color-picker-presence"
            isLoading={searchLoading}
            loader={<ColorsGridSkeleton />}
            visible={hasSearched}
          >
            <ColorsGrid
              {...commonColorGridProps}
              colors={searchResults}
              handleShowMore={handleShowMoreSearch}
              hasShowMore={hasShowMoreSearch}
              showMore={showMoreSearch}
            />
          </Presence>
          <Presence
            {...getCollapsePropsWithOffset('-1.5rem')}
            id="mobile-color-picker-presence"
            isLoading={!hasSearched && colorsLoading}
            loader={<ColorsGridSkeleton />}
            visible={!hasSearched}
          >
            <ColorsGrid
              {...commonColorGridProps}
              colors={activeColors}
              handleShowMore={handleShowMore}
              hasShowMore={hasShowMore}
              showMore={showMore}
              title={translations.subtitle}
            />
          </Presence>

          {!!callToActions?.length && (
            <div className="flex flex-col gap-3">
              {callToActions.map((callToAction, index) => (
                <Button
                  className={twMerge(
                    'min-h-15 w-full',
                    callToAction.highlight ? '' : 'bg-beige-light text-brown-dark hover:bg-brown-dark hover:text-white',
                  )}
                  key={`${callToAction.label}-${index}`}
                  label={callToAction.label}
                  onClick={callToAction.onClick}
                  type="primary"
                />
              ))}
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default MobileColorPickerModal;
