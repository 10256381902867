import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { participateEvent } from './connector';
import { useProfile } from '../../hooks';

const useParticipateEvent = ({
  toasts,
}: {
  toasts?: {
    success: { title: string; description: string };
    error: { title: string; description: string };
  };
}) => {
  const { t } = useTranslation('common');
  const { data: profile, isLoggedIn } = useProfile();

  return useMutation({
    mutationFn: async ({ eventId, formData }: { eventId: string; formData: Record<string, unknown> }) =>
      await participateEvent({
        eventId,
        data: {
          ...formData,
          contactpersonid: profile?.extension_ContactPersonId,
        },
        isLoggedIn,
      }),
    onSuccess: () => {
      if (toasts?.success) {
        toast.success(toasts.success.title, { description: toasts.success.description });
      }
    },
    onError: () => {
      if (toasts?.error) {
        toast.error(toasts.error.title, { description: toasts.error.description });
      } else {
        toast.error(t('participate.error', { ns: 'event' }));
      }
    },
  });
};

export { useParticipateEvent };
