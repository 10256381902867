import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useParticipateEvent } from '../../client-queries';
import { useFormField } from '../../hooks';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = {
  id?: string;
  eventId: string;
  locationId: string;
  skuId: string;
};

const WorkshopForm = ({ id = 'workshop-form', eventId, locationId, skuId }: Props) => {
  const { t } = useTranslation('forms');
  const { firstname, lastname, email, phonenumber, question, termsandconditions, newsletter } = useFormField();
  const [participants, setParticipants] = useState(1);

  const successToast = {
    title: t('success.title'),
    description: t('success.subscribeSubtitle'),
  };
  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('errors.submitSubtitle'),
  };
  const {
    mutate: submitForm,
    isLoading: isSubmitting,
    isSuccess,
    reset,
  } = useParticipateEvent({
    toasts: { success: successToast, error: errorToast },
  });

  const handleSubmit = (vals: FormValues) =>
    submitForm({
      eventId,
      formData: {
        firstname: vals.firstname,
        lastname: vals.lastname,
        email: vals.email,
        phonenumber: vals.phonenumber,
        comment: vals.message,
        onlinecommunication: vals.newsletter,
        quantity: vals.participants,
        locationid: locationId,
        skuid: skuId,
      },
    });

  const updateFormValues = (vals: FormValues) => {
    const newParticipants = Number(vals.participants ?? 1);

    if (participants !== newParticipants) {
      setParticipants(newParticipants);
    }
  };

  const shouldShowField = (field: string) => {
    if (field === 'message') {
      return participants < 2;
    }

    return true;
  };

  const fieldClassName = 'col-span-6 md:col-span-3';

  const userInfoFields: FormField[] = [
    { ...firstname, colStyle: fieldClassName },
    { ...lastname, colStyle: fieldClassName },
    { ...email, colStyle: fieldClassName },
    { ...phonenumber, colStyle: fieldClassName },
    {
      ...question,
      required: false,
      label: t('fields.questionWorkshop'),
      colStyle: 'col-span-6',
    },
  ];

  const checkboxFields: FormField[] = [
    {
      type: 'counter',
      name: 'participants',
      label: t('fields.numberOfParticipants'),
      colStyle: 'col-span-6 mb-2',
      initialValue: 1,
    },
    termsandconditions,
    newsletter,
  ];

  return (
    <DynamicForm
      buttonProps={{ label: t('buttons.subscribe') ?? '' }}
      columns={[
        { id: `${id}-user-info`, fields: userInfoFields },
        { id: `${id}-checkboxes`, fields: checkboxFields, formFieldsWrapperClassName: 'flex flex-col gap-4' },
      ]}
      columnsWrapperClassName="grid gap-6 lg:gap-x-30 md:grid-cols-2"
      eventId={eventId}
      id={id}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={e => updateFormValues(e.values)}
      onSubmit={handleSubmit}
      shouldShowField={shouldShowField}
      variant="light"
    />
  );
};

export default WorkshopForm;
