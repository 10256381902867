import { faCartShopping, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useIsSSR, useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { IProduct, IStore } from '@boss/services';
import { Color, ColorGroup, IArticle, ISearchProduct, SimpleColor } from '@boss/types/b2b-b2c';
import {
  Alert,
  Button,
  ColorPicker,
  ColorPickerSkeleton,
  Image,
  Orb,
  Presence,
  Specifications,
  Tooltip,
} from '@boss/ui';

import {
  ArticlesTable,
  ColorPickerModal,
  CrossSellingModal,
  DeliveryPromises,
  ProductAverageReview,
  StockModal,
} from '../../components';
import { COLOR_TYPE, CONSUMPTION_UNIT } from '../../constants';
import { useAddToBasket, useProductData, useProfile } from '../../hooks';
import {
  getArticlesForSurface,
  getProductColorType,
  isB2b,
  productCardVariant,
  productPageConfig,
  productPanelVariant,
} from '../../utils';
import ArticlesTableSkeleton from '../ArticlesTable/Skeleton';

type Props = {
  product: IProduct | ISearchProduct;
  className?: string;
  selectedStore: IStore | undefined;
  onCalculateSurface?: (articles: IArticle[]) => void;
  amountOfPaint?: number;
  preChosenColor?: Color | null;
  colorGroups?: ColorGroup[] | null;
  showBaseColors?: boolean;
  preChosenColorBase?: string;
};

const PaintGuideResultCard = ({
  amountOfPaint = 0,
  onCalculateSurface,
  product,
  className,
  selectedStore,
  colorGroups,
  preChosenColor,
  preChosenColorBase,
  showBaseColors,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { usps, id } = product;

  const { locale } = useRouter();
  const { t } = useTranslation(['product', 'stores', 'common', 'paintguide']);

  const [selectedColor, setSelectedColor] = useState<SimpleColor | undefined>();
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState({});

  /**
   * This is about as good as it gets without restructering and rethinking the whole PDP functionality
   */
  const {
    favoriteColors,
    colorsByGroup,
    searchColors,
    searchLoading,
    activeProductOption,
    setSelectedColorGroup,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    setSearchedValue,
    searchValue,
    addColor,
    searchedValue,
    setSavedStore,
    setSearchValue,
    viewedColors,
    showPrivatePrices,
    stock,
    showColors,
    handleTogglePrivatePrices,
    articles,
    articlesLoading,
    setSelectedColorPerGroup,
    selectedColorPerGroup,
  } = useProductData({
    showBaseColors: !!showBaseColors,
    isPaint: true,
    productId: product.id,
    selectedArticleIdsObject: selectedArticles,
    selectedColor,
    hasArticles: !!product.articles,
    colorTypes: product.colortypegroups as string[] | null,
    colorGroupType: preChosenColorBase || COLOR_TYPE.MYMX,
  });

  // When there are no productOptions, the product is only available in one color/transparency
  const enableColors = showColors && !!productOptions?.length && !!colorGroups?.length;

  const [showCrossSellingModal, setShowCrossSellingModal] = useState(false);

  const { hasArticlesSelected, setSelectedArticleIdsObject, selectedArticleIdsObject, addToBasket } = useAddToBasket({
    articles: articles ?? [],
    selectedColor,
    product,
  });

  const handleAddToBasket = async () => {
    addToBasket({
      callback: () => (isB2b ? () => '/' : setShowCrossSellingModal(true)),
      isColorProduct: !!enableColors,
    });
  };

  useEffect(() => {
    setSelectedArticles(selectedArticleIdsObject);
  }, [selectedArticleIdsObject]);

  useEffect(() => {
    if (preChosenColorBase) {
      handleProductOptionClick({
        key: preChosenColorBase,
        value: preChosenColorBase,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preChosenColorBase]);

  const isSSR = useIsSSR();

  const { needsLoginForBasket } = productPageConfig;
  const { isLoggedIn } = useProfile();

  // Handle stock and store selection
  const enableStock = hasArticlesSelected;

  const showProductActions = (needsLoginForBasket && isLoggedIn && !isSSR) || !needsLoginForBasket;

  const colorType = getProductColorType(product);

  const [showStockModal, setShowStockModal] = useState(false);
  const isPanelLoading = isSSR || searchLoading;

  const handleCloseModal = () => {
    setShowStockModal(false);
    setShowColorModal(false);
    setSearchedValue('');
  };

  const toggleStockModal = () => {
    setShowStockModal(!showStockModal);
  };

  useEffect(() => {
    const articlesNeeded = getArticlesForSurface(amountOfPaint, articles);

    if (articlesNeeded) {
      setSelectedArticleIdsObject(articlesNeeded);
    }
  }, [articles, amountOfPaint, setSelectedArticleIdsObject]);

  const handleProductPanelSearch = () => {
    setSearchedValue(searchValue);

    setShowColorModal(true);
  };

  const handleColorGroupClick = (color?: SimpleColor) => {
    if (color) {
      setSelectedColorGroup(color);
    }

    setShowColorModal(true);
  };

  const onColorClick = (simpleColor: SimpleColor) => {
    _setSelectedColor(simpleColor);
    addColor(simpleColor);
    setSelectedArticleIdsObject({});
  };

  const _setSelectedColor = (color: SimpleColor, colorBase?: string) => {
    if (colorBase) {
      handleProductOptionClick({
        key: colorBase,
        value: colorBase,
      });
    }

    if (activeProductOption?.key) {
      setSelectedColorPerGroup({
        ...selectedColorPerGroup,
        [activeProductOption.key]: color,
      });
    }
    setSelectedColor(color);
  };

  const setProductOption = (option: { key: string; value: string }) => {
    const color = selectedColorPerGroup[option.key];

    setSelectedColor(color ?? undefined);

    handleProductOptionClick({
      key: option.key as COLOR_TYPE,
      value: option.value,
    });
  };

  useEffect(() => {
    if (preChosenColor) {
      _setSelectedColor(preChosenColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preChosenColor]);

  return (
    <div className={twMerge('shadow-l rounded-5 justify-between gap-10 px-7 pb-7 pt-8 md:flex', className)}>
      {/* first column */}
      <div className="xs:flex">
        <div className="min-w-13 mb-5 flex flex-col items-center md:mb-0">
          <Image
            alt={product.name}
            className="h-25 xs:w-25 mb-2 w-full object-contain"
            height={100}
            src={product.packshot.src}
            useNext
            width={100}
          />
          {!isB2b && <ProductAverageReview elementSuffix="detail" id={product.id} smallScale />}
        </div>

        <div className="xs:ml-8 flex flex-col">
          <h4 className="mb-5">{product.name}</h4>
          {usps?.length && (
            <Specifications
              border={false}
              fontStyle="md:text-lg text-generic-brown"
              items={usps.map((usp, i) => (
                <RichText
                  content={usp}
                  key={`${id}-specification-${i}`}
                  mapperOptions={{ locale }}
                  renderOptions={{ noParagraph: true }}
                />
              ))}
              variant="checked"
            />
          )}
          {productPanelVariant === 'secondary' && enableColors && (
            <Presence
              className="my-10"
              id="product-panel-color-picker-card"
              isLoading={isPanelLoading}
              loader={showColors && <ColorPickerSkeleton variant={productPanelVariant} />}
              visible={showColors}
            >
              <ColorPicker
                activeProductOption={activeProductOption}
                colorOptions={colorsByGroup}
                colorType={colorType}
                colors={colorGroups}
                favoriteColors={favoriteColors}
                hasSearched={!!searchedValue}
                onChangeGroupClick={handleColorGroupClick}
                onColorClick={onColorClick}
                onProductOptionClick={setProductOption}
                onSearch={handleProductPanelSearch}
                productOptions={productOptions}
                recentColors={viewedColors}
                searchColors={searchColors}
                searchLoading={searchLoading}
                searchValue={searchValue}
                selectedColor={selectedColor}
                setSearchValue={setSearchValue}
                translations={t('productPanel.colorPicker', { ns: 'product', returnObjects: true })}
                variant={productPanelVariant}
              />
            </Presence>
          )}
          {productPanelVariant === 'primary' && (
            <>
              {selectedColor && (
                <div className="mb-2 mt-4 flex items-center">
                  <Orb size="sm" style={{ backgroundColor: selectedColor.rgb }} />
                  <p className="ml-2">{selectedColor.name ?? selectedColor.code}</p>
                </div>
              )}
              <button
                className="text-red-light my-3 flex items-center gap-1 whitespace-nowrap"
                onClick={() => setShowColorModal(true)}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="underline">{t('productPanel.colorPicker.changeColor')}</span>
              </button>
            </>
          )}
          {onCalculateSurface && (
            <div className="my-10 flex flex-row items-center gap-4">
              <Button
                className="bg-blue [&>*]:small flex items-center gap-2 text-white"
                label={t('productPanel.paintCalculator.calculateAmount', { ns: 'product' })}
                onClick={() => onCalculateSurface(articles ?? [])}
                type="primary"
              />
              {!!amountOfPaint && (
                <div className="flex items-center gap-2">
                  <span>=</span>
                  <Tooltip
                    content={
                      <span className="min-w-105 caption">
                        {t('productPanel.paintCalculator.calculateSurfaceDisclaimer', { ns: 'product' })}
                      </span>
                    }
                  >
                    <span className="text-paint-result">
                      <span className="h4">{amountOfPaint.toFixed(2)}</span>
                      {t(product.consumptionUnit === CONSUMPTION_UNIT.KILO ? 'kilo' : 'liter', { ns: 'common' })}
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          <DeliveryPromises
            className="mt-auto hidden w-full flex-col-reverse md:flex"
            onShopDeliveryClick={toggleStockModal}
            savedStore={selectedStore}
            stock={stock}
            stockEnabled={enableStock}
          />
        </div>
      </div>

      {/* mobile promises */}
      <DeliveryPromises
        className="my-8 w-full flex-col-reverse md:hidden"
        onShopDeliveryClick={() => setShowStockModal(true)}
        savedStore={selectedStore}
        stock={stock}
        stockEnabled={enableStock}
      />

      {/* second column */}
      <div className="flex flex-col gap-5 md:ml-10">
        <h4>{t('advice', { ns: 'paintguide' })}</h4>
        {!articlesLoading ? (
          articles?.length ? (
            <ArticlesTable
              articleType="paint"
              articles={articles}
              isLoading={articlesLoading}
              isLoggedIn={isLoggedIn}
              selectedArticleIdsObject={selectedArticleIdsObject}
              setSelectedArticleIdsObject={setSelectedArticleIdsObject}
              showPrivatePrices={showPrivatePrices}
              togglePrivatePrices={productPanelVariant === 'secondary' ? handleTogglePrivatePrices : undefined}
              type={showProductActions ? 'default' : 'slim'}
            />
          ) : (
            <Alert type="error">
              <div className="flex flex-col gap-2">
                {t('productPanel.noArticles')}
                <Button
                  className="text-black"
                  label={t('productPanel.selectOtherColor')}
                  onClick={() => setShowColorModal(true)}
                  type="link"
                />
              </div>
            </Alert>
          )
        ) : (
          <div className="w-100">
            <ArticlesTableSkeleton />
          </div>
        )}
        {/* here comes the price component  with the strike/discount thingy */}
        <Button
          className="w-full justify-center px-6 py-4 text-center [&>*]:text-base [&>*]:md:text-xl"
          disabled={!hasArticlesSelected}
          icon={faCartShopping}
          iconPosition="left"
          label={t('productPanel.button.addToBasket')}
          labelClassName="w-auto"
          onClick={handleAddToBasket}
          type="primary"
        />
      </div>

      {showColors && showColorModal && !!colorGroups?.length && (
        <ColorPickerModal
          colorGroups={colorGroups}
          colorType={colorType}
          initialColorGroup={selectedColorGroup}
          initialSearchValue={searchedValue}
          onClose={handleCloseModal}
          productId={product.id}
          selectedColor={selectedColor}
          setSelectedColor={_setSelectedColor}
          setSelectedColorGroup={setSelectedColorGroup}
        />
      )}

      {showStockModal && (
        <StockModal
          articles={articles}
          onClose={handleCloseModal}
          productImage={product.packshot}
          saveStore={setSavedStore}
          selectedArticleIdsObject={selectedArticleIdsObject}
          variant={productCardVariant}
        />
      )}

      {showCrossSellingModal && !!articles?.length && (
        <CrossSellingModal
          articles={articles}
          crossSellArticles={product.crossSell}
          onClose={() => setShowCrossSellingModal(false)}
          product={product}
          selectedArticleIdsObject={selectedArticleIdsObject}
          selectedColor={selectedColor}
        />
      )}
    </div>
  );
};

export default PaintGuideResultCard;
