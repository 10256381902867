import { event } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const HEADERS = { 'Content-Type': 'application/json' };

export const getEvents = (locale: string) => {
  return bossApi<event.Event[]>(`/service/v2/events`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

export const participateEvent = ({
  eventId,
  data,
  isLoggedIn,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
}) => {
  const basePath = isLoggedIn ? '/service/v2' : '/guest/service/v2';

  return bossApi(`${basePath}/events/${eventId}/participants`, {
    ...DEFAULT_OPTIONS,
    headers: HEADERS,
    method: 'POST',
    body: data,
  });
};

export const getEventById = (locale: string, eventId: string) => {
  return bossApi<event.Event>(`/service/v2/events/${eventId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
